<template>
  <span>
    <b-card  v-if="userProfileLabels.length > 0">
      <b-list-group>
        <b-list-group-item v-for="item in userProfileLabels" :key="item.key">
          {{ item.getLabel() }}: {{ userProfileContents[item.key] }}
        </b-list-group-item>
        <b-list-group-item>
          <label>
            {{ messages.getFieldLanguage() }}:<br />
            <b-form-select
              v-model="locale"
              :disabled="isLocaleLocked"
              :options="localeOptions"
            />
          </label>
        </b-list-group-item>
        <b-list-group-item v-if="userIsSuperManager">
            <label for="importantMessageLabel">
              {{ messages.getLabelBroadcastMessage() }}:
            </label>
            <b-form-textarea
              class="input text-area-style"
              v-model="importantMessage"
              :placeholder="messages.getLabelBroadcastMessageDescription()"
              id="importantMessageLabel"
            >
            </b-form-textarea>
            <b-button
              @click="saveImportantMessage"
              variant="primary"
              class="save-button-style"
            >
              {{ messages.getActionSaveBroadcastMessage() }}
            </b-button>
            <b-button
              @click="deleteImportantMessage"
              variant="danger"
              class="remove-button-style"
            >
              {{ messages.getActionDeleteBroadcastMessage() }}
            </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <div v-else class="text-center h-100 d-flex justify-content-center align-items-center">
      <b-spinner :label="messages.getLabelLoading()"></b-spinner>
    </div>
  </span>
</template>

<script>
import axios from 'axios'
import { messages } from '@/utils/strings'
import { MECHANIC, CUSTOM_SERVICE_ADMIN, SUPERVISOR, SUPER_MANAGER } from '@/constants/permissions'
import locales from '@/constants/locales'

export default {
  name: 'UserProfile',
  data () {
    return {
      importantMessage: this.$store.state.profile.importantMessage,
      userProfileLabels: [],
      userProfileContents: [],
      messages
    }
  },
  computed: {
    localeOptions () {
      const localeOptions = []
      for (const [propKey, propValue] of Object.entries(locales)) {
        localeOptions.push({ text: propValue, value: propKey })
      }
      return localeOptions
    },
    locale: {
      get () {
        console.debug('get locale: ', this.$store.state.profile.locale)
        return this.$store.state.profile.locale
      },
      set (locale) {
        console.debug('set locale: ', locale)
        this.$store.dispatch('saveLocale', locale)
      }
    },
    isLocaleLocked () {
      return this.$store.state.profile.isLocaleLocked ?? false
    },
    userIsMaintenanceTechnician () {
      return this.$store.getters.hasCurrentUserPermission(MECHANIC)
    },
    userIsCustomServiceAdmin () {
      return this.$store.getters.hasCurrentUserPermission(CUSTOM_SERVICE_ADMIN)
    },
    userIsSupervisor () {
      return this.$store.getters.hasCurrentUserPermission(SUPERVISOR)
    },
    userIsSuperManager () {
      return this.$store.getters.hasCurrentUserPermission(SUPER_MANAGER)
    }
  },
  methods: {
    setFieldsToShow (item) {
      const profileObj = {}
      const listSeparator = ', '

      // Show email always.

      this.userProfileLabels.push({ key: 'email', getLabel: messages.getLabelEmail })
      profileObj.email = item.userEmail

      // Add fields for different user roles. Same user can have many roles,
      // so same property can be added more than once, but they seem to be shown only once.

      if (this.userIsMaintenanceTechnician) {
        this.userProfileLabels.push({ key: 'plants', getLabel: messages.getFieldPlants })
        this.userProfileLabels.push({ key: 'workCenters', getLabel: messages.getFieldWorkCenters })
        profileObj.plants = item.plants.join(listSeparator)
        profileObj.workCenters = item.workCenters.join(listSeparator)
      }

      if (this.userIsCustomServiceAdmin) {
        this.userProfileLabels.push({ key: 'plants', getLabel: messages.getFieldPlants })
        profileObj.plants = item.plants.join(listSeparator)
      }

      if (this.userIsSupervisor) {
        if (!('plants' in profileObj)) {
          this.userProfileLabels.push({ key: 'plants', getLabel: messages.getFieldPlants })
          profileObj.plants = item.plants.join(listSeparator)
        }
        this.userProfileLabels.push({ key: 'plannerGroups', getLabel: messages.getFieldPlannerGroups })
        profileObj.plannerGroups = item.plannerGroups.join(listSeparator)
      }
      // All user roles have storage locations
      this.userProfileLabels.push({ key: 'storageLocations', getLabel: messages.getFieldStorageLocations })
      profileObj.storageLocations = item.storageLocations.join(listSeparator)
      // Table contents are a list, in this case there is only one row.
      this.userProfileContents = profileObj
    },
    getUserProfile () {
      const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/user/profile`
      axios.get(url)
        .then((resp) => {
          this.setFieldsToShow(resp.data)
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
    },
    saveImportantMessage () {
      // Here we dispatch the save action to the store where api call to backend is located.
      this.$store.dispatch('saveImportantMessage', this.importantMessage)
      // important-message-toast-id is configured in ViewWrapper.vue file and we
      // use it so we can close the toast when we want to.
      this.$bvToast.hide('important-message-toast-id')
    },
    deleteImportantMessage () {
      // Here we dispatch the delete action to vuex store where the api call to backend is located
      // and empty the local importantMessage textarea field.
      this.$store.dispatch('deleteImportantMessage')
      this.importantMessage = ''
      // important-message-toast-id is configured in ViewWrapper.vue file and we
      // use it so we can close the toast when we want to.
      this.$bvToast.hide('important-message-toast-id')
    }
  },
  mounted () {
    this.getUserProfile()
  }
}
</script>

<style scoped>
.text-area-style {
  margin-bottom: 4px;
}
.save-button-style {
  margin-right: 2px;
}
.remove-button-style {
  margin-left: 2px;
}
</style>
